<template>
  <div class="w80-login px-5 px-sm-10 py-5">
    <div class="row mx-auto px-md-10">
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 container-text-home">
        <p class="font-white font-regular text-home mt-10"><strong class="strong-text-home">Bienvenido a Mobil®
            Predictor, en donde demuestras que eres experto en la
            escudería Oracle Red Bull Racing y su alianza tecnológica con Mobil.</strong> Predice
          los resultados de cada carrera y vive la adrenalina a cambio de premios
          increíbles.</p>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <img class="image-home my-5" src="../../../assets/2024/ChecoMax_home.png" alt="ChecoMaxRedBullR20">
      </div>
    </div>
    <div class="mt-3 boton-empezar-mobile">
      <a href="/iniciar-sesion" class="home-button">
        Empezar
      </a>
    </div>
    <div class="px-md-10 d-flex align-stretch justify-end">
      <p class="font-white text-home-brand italica">Powered by Mobil<sup>®</sup> Racing Club</p>
    </div>
  </div>
</template>

<script src="./home.js" />
<style src="./home.scss" lang="scss" />
<script>
export default {
  mounted() {
    fbq('track', 'Traffic');
  },
}
</script>
