import Cookies from 'js-cookie'
import mobilFooter from './components/mobilFooter/mobilFooter.vue'
import anime from 'animejs'
// import { getParticipation } from './services'

export default {
  name: 'App',
  components: {
    mobilFooter
  },
  data() {
    return {
      mainMenuButton: false,
      auth: ''
    }
  },
  methods: {
    redirigir(name) {
      this.$router.push({ name })
    },
    abrir(url) {
      window.open(url, '_blank')
    },
    toggleMenu() {
      this.mainMenuButton = !this.mainMenuButton
      this.animateButton()
    },
    animateButton() {
      if (this.mainMenuButton) {
        // Animación para transformar en X
        anime({
          targets: '.hamburger-icon span:nth-child(1)',
          rotate: [0, 50],
          translateY: [0, 12],
          duration: 500,
          easing: 'easeInOutQuad'
        })
        anime({
          targets: '.hamburger-icon span:nth-child(2)',
          opacity: [1, 0],
          duration: 500,
          easing: 'easeInOutQuad'
        })
        anime({
          targets: '.hamburger-icon span:nth-child(3)',
          rotate: [0, -50],
          translateY: [0, -12],
          duration: 500,
          easing: 'easeInOutQuad'
        })
      } else {
        // Animación para volver al ícono de hamburguesa
        anime({
          targets: '.hamburger-icon span:nth-child(1)',
          rotate: [50, 0],
          translateY: [8, 0],
          duration: 500,
          easing: 'easeInOutQuad'
        })
        anime({
          targets: '.hamburger-icon span:nth-child(2)',
          opacity: [0, 1],
          duration: 500,
          easing: 'easeInOutQuad'
        })
        anime({
          targets: '.hamburger-icon span:nth-child(3)',
          rotate: [-50, 0],
          translateY: [-8, 0],
          duration: 500,
          easing: 'easeInOutQuad'
        })
      }
    },
    logOut() {
      Cookies.remove('predictor')
      this.auth = false
      setTimeout(() => {
        this.$router.push('/')
      }, 2000)
    }
  },
  mounted() {
    if (document.cookie.includes('predictor')) {
      this.auth = true
    }
    const navIcons = document.querySelectorAll('#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4')
    navIcons.forEach(navIcon => {
      navIcon.addEventListener('click', () => {
        navIcon.classList.toggle('open')
        const containerMenuMobileOptions = document.querySelector('.container-menu-mobile-options')

        if (containerMenuMobileOptions.classList.contains('show')) {
          containerMenuMobileOptions.classList.remove('show')
          containerMenuMobileOptions.classList.add('hide')

          // Esperar a que la animación termine antes de ocultar el elemento
          setTimeout(() => {
            containerMenuMobileOptions.style.display = 'none'
            containerMenuMobileOptions.classList.remove('hide')
          }, 300) // La duración de la transición en milisegundos
        } else {
          containerMenuMobileOptions.style.display = 'flex'
          setTimeout(() => {
            containerMenuMobileOptions.classList.add('show')
          }, 10) // Pequeño retraso para permitir que el navegador registre el cambio de display
        }
      })
    })
  }
}
