<template>
  <div class="w80-crpwd my-20 px-sm-10">
    <div class="row text-center">
      <div class="col-lg-6 col-md-6 col-sm-12 p-20-puntuaciones">
        <div class="row container-misresultados">
          <div class="col-lg-4 col-md-4 col-xs-12">
            <img class="ChecoPuntuaciones" src="../../../assets/2024/ChecoDashboard.png" alt="">
          </div>
          <div class="col-lg-8 col-md-8 col-xs-12 py-10">
            <h1 class="font-white text-center mb-3">Mis Resultados</h1>
            <hr class="separador-puntuaciones mb-3">
            <div v-for="(data, index) in filteredData" :key="index" class="row dashboard-score">
              <div class="col-4">
                <p class="font-white font-regular text-score mt-2 mb-3">Mi posición</p>
                <p class="scoreboard font-regular">{{ data.ranking }}</p>
              </div>
              <div class="col-5">
                <p class="font-white font-regular text-score mt-2 mb-3">Usuario</p>
                <p class="scoreboard font-regular">{{ data.name }}</p>
              </div>
              <div class="col-3">
                <p class="font-white font-regular text-score mt-2 mb-3">Puntos</p>
                <p class="scoreboard font-regular">{{ data.score }}</p>
              </div>
            </div>
            <div class="tabla-individual mt-5">
              <h4 class="font-white font-regular">Puntuación por carrera</h4>
              <table>
                <tr>
                  <th class="font-white font-regular">Carrera</th>
                  <th class="font-white font-regular">Puntos</th>
                </tr>
                <tr class="font-white font-regular bold" v-for="(value, key) in resultadosCarrera" :key="key">
                  <td class="puntosPorCarrera">{{ key }}</td>
                  <td class="puntosPorCarrera">{{ value.totalPuntos }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="mt-10 mb-10">
          <button class="btn btn-primary btn-lg btn-launch-puntuaciones font-white"
            @click="redirectWithTimeout('/predicciones-pilotos-checo')">Predecir el resultado</button>
        </div>
        <v-row class="mt-5 d-flex align-stretch justify-center">
          <p class="font-white text-home-brand italica mb-10">Powered by Mobil<sup>®</sup> Racing Club</p>
        </v-row>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <h1 class="font-white">Top 10</h1>
        <div class="container-top10">
          <div class="row dashboard-table-content">
            <div class="col-4 p-none">
              <p class="font-white font-regular">Posicion</p>
            </div>
            <div class="col-4 p-none">
              <p class="font-white font-regular">Usuario</p>
            </div>
            <div class="col-4 p-none">
              <p class="font-white font-regular">Puntos</p>
            </div>
          </div>
          <div v-for="(data, index) in rankingData" :key="index" class="row dashboard-table-content">
            <div class="col-4 p-none">
              <p class="font-white score-boards ">{{ data.ranking }}</p>
            </div>
            <div class="col-4 p-none">
              <p class="font-white score-boards ">{{ data.name + ' ' + data.first_name.charAt(0) + '.' }}</p>
            </div>
            <div class="col-4 p-none">
              <p class="font-white score-boards ">{{ data.score }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./dashboard.js" />
<style src="./dashboard.scss" lang="scss" />
<script>
import axios from "axios";

export default {
  name: 'dashboard',
  metaInfo: {
    title: 'Tabla de posiciones | Mobil Predictor',
    meta: [
      { name: 'title', content: 'Tabla de posiciones | Mobil Predictor' },
      { name: 'description', content: '¿Bienvenido de nuevo a Mobil Predictor. Acelera hacia la emoción e inicia sesión en tu cuenta para disfrutar de la experiencia de predicción más emocionante' }
    ]
  },
  data() {
    return {
      rankingData: [],
      filteredData: [],
      resultadosCarrera: {}
    };
  },
  mounted() {
    this.fetchData();
    if (!localStorage.getItem('predictor')) {
      this.$router.push('/iniciar-sesion');
    }
    this.puntosPorCarrera();
  },
  methods: {
    fetchData() {
      axios
        .get('https://www.mobilpredictor.com.mx/datos/api/ranking')
        .then((response) => {
          // Solo muestra los 10 primeros
          this.rankingData = response.data.filter(data => data.ranking !== null).sort((a, b) => a.ranking - b.ranking).slice(0, 10);

          const predictor = JSON.parse(localStorage.getItem('predictor'));
          const user = predictor.email;

          // Mostrar el puntaje de cada usuario
          this.filteredData = response.data.filter(data => data.email === user);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    puntosPorCarrera() {
      const predictor = JSON.parse(localStorage.getItem('predictor'));
      const email = predictor.email;

      axios.post('https://www.mobilpredictor.com.mx/datos/api/puntuacionesCarrera', { email })
        .then((response) => {
          this.resultadosCarrera = response.data;
          console.log(this.resultadosCarrera);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    redirectWithTimeout(address) {
      setTimeout(() => {
        window.location.href = address;
      }, 1500);
    }
  },
};
</script>
