<template>
  <div class="w100 px-5 px-sm-10 my-20">
    <div class="row mx-auto px-md-10">
      <div class="col-lg-4 col-md-4 d-none d-md-block d-sm-none"></div>
      <div class="col-lg-4 col-md-4 col-sm-8 col-xs-12">
        <div class="contenedor-mensaje text-center">
          <p class="font-white font-regular exito-titulo">Tus Predicciones han sido enviadas.</p>
          <p class="font-white font-bold exito-texto">¡Mucha Suerte!</p>
          <button class="font-regular exito-boton" @click="volverAPredicciones">Regresar</button>
        </div>
        <div class="px-md-10 text-center mt-10">
          <p class="font-white text-exito-brand italica">Powered by Mobil<sup>®</sup> Racing Club</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 d-none d-md-block d-sm-none"></div>
    </div>
  </div>
</template>

<!-- <script src="./exito.js" /> -->
<style src="./prediccionesEnviadas.scss" lang="scss" />
<script>
export default {
  data() {
  },
  methods: {
    volverAPredicciones() {
      this.$router.push('/predicciones-pilotos-checo')
    }
  },
  mounted() {
    window.fbq('track', 'CompleteRegistration')
  }
}
</script>
