<template>
  <div class="w80-Upwd my-20">
    <div class="row text-center">
      <div class="col-lg-2 col-md-3 col-sm-2 d-none d-md-block d-sm-none">
        <img class="Checo" src="../../../assets/Checo_updatepwd.png" alt="Checo" />
      </div>
      <div class="col-lg-8 col-md-6 col-sm-8 col-xs-12">
        <div class="contenedor-form mt-20 pt-5 pb-10">
          <!-- <img class="headregister d-sm-none" src="../../../assets/registerHead.png" alt="Mobile Logo" /> -->
          <!-- <h1 class="font-white f50 mt-5">Mobil Predictor</h1> -->
          <h2 class="font-white font-regular text-center mt-5 mb-5 f30">
            Cambiar nueva contraseña
          </h2>
          <hr class="separador_updatepwd">
          <form action="" class="mt-5 w100" @submit.prevent="submitUpdatePassword">
            <div class="input-group container-input-updatepwd d-flex mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-lock"></i>
                </span>
              </div>
              <input :type="showPassword1 ? 'text' : 'password'" class="form-control font-regular input-password-login" id="new_password"
              v-model="form.newPassword" placeholder="Contraseña" />
              <i class="fa fa-eye password-icon-login" :class="{ 'fa-eye-slash': showPassword }"
                @click="showPassword1 = !showPassword1"></i>
            </div>

            <div class="input-group container-input-updatepwd d-flex">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-lock"></i>
                </span>
              </div>
              <input :type="showPassword2 ? 'text' : 'password'" class="form-control font-regular input-password-login" id="confirm_password"
              v-model="form.confirmPassword" placeholder="Confirmar contraseña" />
              <i class="fa fa-eye password-icon-login" :class="{ 'fa-eye-slash': showPassword }"
                @click="showPassword2 = !showPassword2"></i>
            </div>

            <div class="d-flex justify-center mt-10">
              <button class="btn btn-primary btn-lg btn-launch-crpwd">
                Actualizar
              </button>
            </div>
          </form>
          <!-- <a class="font-white mt-5" href="">Olvidaste tu contraseña</a> -->
        </div>
        <p class="font-white text-home-brand italica brand-upw">Powered by Mobil<sup>®</sup> Racing Club</p>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-4 d-none d-sm-block">
        <img class="MaxV" src="../../../assets/Max_updatepwd.png" alt="MaxV" />
      </div>
    </div>
  </div>
</template>

<script src="./actualizarPassword.js" />
<style src="./actualizarPassword.scss" lang="scss" />
<style src="../../../App.scss" lang="scss"/>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';

  export default {
    name: 'actualizarPassword',
    metaInfo: {
    title: 'Cambia tu contraseña | Mobil Predictor',
    meta: [
        { name: 'title', content: 'Cambia tu contraseña | Mobil Predictor' },
        { name: 'description', content: '¿Necesitas cambiar tu contraseña? No te preocupes. ¡Entra aquí y vuelve a jugar en Mobil Predictor!' }
      ]
    },
    data() {
      return {
        form: {
          email: this.$route.params.correo,
          newPassword: '',
          confirmPassword: '',
        },
        showPassword1: false,
        showPassword2: false
      };
    },
    methods: {
      async submitUpdatePassword() {
      try {
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        const response = await axios.post('https://www.mobilpredictor.com.mx/datos/api/password/update', this.form);

        if (response.data.message == 'EmailNoExist') {
          Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'No se pudo actualizar la contraseña, intenta de nuevo',
              background: "rgba(230, 230, 230, 0.12)",
              color: "#fff",
            });
        }

        if (response.data.message == 'Success') {
          Swal.fire({
              icon: 'success',
              title: 'Contraseña actualizada',
              text: 'Tu contraseña ha sido actualizada correctamente',
              background: "rgba(230, 230, 230, 0.12)",
              color: "#fff",
            }).then(() => {
              this.$router.push({ name: 'login' });
            });

        }

        if (response.data.message == 'PasswordNotMatch') {
          Swal.fire({
              icon: 'warning',
              title: 'Error',
              text: 'Las contraseñas no coinciden',
              background: "rgba(230, 230, 230, 0.12)",
              color: "#fff",
            });
        }

        if (response.data.message == 'pwdIsUsed') {
          Swal.fire({
              icon: 'info',
              title: 'Error',
              text: 'No puedes usar una contraseña que ya hayas utilizado anteriormente',
              background: "rgba(230, 230, 230, 0.12)",
              color: "#fff",
            });
        }

      } catch (error) {
        // console.error(error);
      }
    }
  }
};
</script>
