<template>
  <div class="w80 my-10">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-1 d-none d-md-block d-sm-none">
        <div class="prediccionesCheco-container-texto">
          <h1 class="font-white font-regular prediccionesCheco-titulo">
            Predicciones
          </h1>
          <p class="font-white font-regular prediccionesCheco-texto">
            Es tu turno de pisar el acelerador.<br />Añade tus avances y
            pronosticos para la proxima Gran Carrera
          </p>
          <h1 class="font-white font-bold texto-mobile-titulo">
            ¡Es muy sencillo!
          </h1>
          <p class="font-white font-regular texto-mobile-texto">
            Para empezar a ganar puntos contesta con tus predicciones sobre
            Checo Pérez y Max Verstappen para la carrera.
          </p>
          <p class="font-white font-regular texto-mobile-texto">
            Después contesta las 2 preguntas Mobil y envía tus resultados.
          </p>
          <p class="font-white font-regular texto-mobile-texto">
            ¿Quieres una pista? Ve a nuestras redes sociales.
          </p>
          <p class="font-white font-regular texto-mobile-texto">
            ¡Y listo! Queremos verte en el podio, asi que no te olvides de
            contestar todos los campos
          </p>
        </div>
        <p class="font-white text-home-brand prediccionesCheco-brand italica mb-3">
          Powered by Mobil<sup>®</sup> Racing Club
        </p>
      </div>
      <div class="col-lg-2 col-sm-1 d-none d-lg-block d-sm-none text-center">
        <img class="prediccionesCheco" src="../../../../../src/assets/2024/PrediccionesCheco.png" alt="Checo" />
      </div>
      <div class="col-lg-6 col-md-8 col-sm-12 col-xs-12">
        <div class="contenedor-predicciones text-center py-1">
          <h1 class="font-white f40 mt-5">Gran Premio de Brasil</h1>
          <img class="mt-3 mb-3 d-sm-none checo_dm" src="../../../../assets/ChecoPredicciones.png" alt="" />
          <div class="texto-mobile">
            <h1 class="font-white font-bold texto-mobile-titulo">
              ¡Es muy sencillo!
            </h1>
            <p class="font-white font-regular texto-mobile-texto">
              Para empezar a ganar puntos contesta con tus predicciones sobre
              Checo Pérez y Max Verstappen para la carrera.
            </p>
            <p class="font-white font-regular texto-mobile-texto">
              Después contesta las 2 preguntas Mobil y envía tus resultados.
            </p>
            <p class="font-white font-regular texto-mobile-texto">
              ¿Quieres una pista? Ve a nuestras redes sociales.
            </p>
            <p class="font-white font-regular texto-mobile-texto">
              ¡Y listo! Queremos verte en el podio, asi que no te olvides de
              contestar todos los campos
            </p>
          </div>
          <div class="container-botones-predicciones">
            <div class="">
              <a href="/predicciones-pilotos-checo" class="piloto font-white page-predicciones">Checo</a>
            </div>
            <div class="boton-secundario">
              <button @click="alertaCambioPaginaMax" class="piloto font-white font-regular font-regular opacity">
                Max
              </button>
            </div>
            <div class="boton-secundario">
              <button @click="alertaCambioPaginaMobil" class="piloto font-white font-regular font-regular opacity">
                Mobil
              </button>
            </div>
          </div>
          <div class="predicciones">
            <!-- <p class="font-white f40 mt-5" v-if="isFormVisible">
              Predicciones no disponibles, se abren el lunes antes de la carrera.
            </p> -->
            <p class="font-white f40 mt-5" v-if="!showQuestions">
              Predicciones no disponibles, se abren el lunes antes de la carrera.
            </p>
            <!-- <form class="" action="" @submit.prevent="submitFormQuestionsMobil" v-if="!isFormVisible"> -->
              <form class="" action="" @submit.prevent="submitFormQuestionsMobil" v-if="showQuestions">
              <!-- Pregunta 1 -->
              <p class="pregunta font-white font-regular mt-3">
                {{ form[0].pregunta1 }}
              </p>
              <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[0].respuesta1">
                  <option v-for="num in 20" :value="num" :key="num">{{ num }}</option>
                  <option value="DNF">DNF</option>
                </select>
                <p class="font-white font-regular score mt-3">100 Puntos</p>
              </div>
              <!-- Fin de pregunta 1 -->
              <!-- Pregunta 2 -->
              <p class="pregunta font-white font-regular mt-3">
                {{ form[1].pregunta2 }}
              </p>
              <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[1].respuesta2">
                  <option v-for="num in 20" :value="num" :key="num">{{ num }}</option>
                </select>
                <p class="font-white font-regular score mt-3">100 Puntos</p>
              </div>
              <!-- Fin de pregunta 2 -->
              <!-- Pregunta 3 -->
              <p class="pregunta font-white font-regular mt-3">
                {{ form[2].pregunta3 }}
              </p>
              <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[2].respuesta3">
                  <option v-for="num in 20" :value="num" :key="num">{{ num }}</option>
                </select>
                <p class="font-white font-regular score mt-3">50 Puntos</p>
              </div>
              <!-- Fin de pregunta 3 -->
              <!-- Pregunta 4 -->
              <p class="pregunta font-white font-regular mt-3">
                {{ form[3].pregunta4 }}
              </p>
              <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[3].respuesta4">
                  <option value="Rojos">Rojos: neumáticos blandos</option>
                  <option value="Amarillos">Amarillos: neumáticos medios</option>
                  <option value="Blancos">Blancos: neumáticos duros</option>
                  <option value="Verdes">Verdes: neumáticos intermedios</option>
                  <option value="Azules">Azules: neumáticos de lluvia extrema</option>
                </select>
                <p class="font-white font-regular score mt-3">50 Puntos</p>
              </div>
              <!-- Fin de pregunta 4 -->
              <!-- Pregunta 5 -->
              <p class="pregunta font-white font-regular mt-3">
                {{ form[4].pregunta5 }}
              </p>
              <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[4].respuesta5">
                  <option value="si">Sí</option>
                  <option value="no">No</option>
                </select>
                <p class="font-white font-regular score mt-3">25 Puntos</p>
              </div>
              <!-- Fin de pregunta 5 -->
              <div class="d-flex justify-center my-5">
                <button class="btn btn-lg"
                  :class="{ 'btn-launch-predicciones': isFormComplete, 'btn-launch-predicciones-disabled': !isFormComplete }"
                  :disabled="!isFormComplete">
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./pilotos.js" />
<style src="./pilotos.scss" lang="scss" />
<style src="../../../../App.scss" lang="scss" />

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import Router from 'vue-router';

export default {
  name: "pilotos",
  metaInfo: {
    title: "Haz tus predicciones sobre Checo Pérez - Mobil Predictor",
    meta: [
      {
        name: "title",
        content: "Haz tus predicciones sobre Checo Pérez - Mobil Predictor",
      },
      {
        name: "description",
        content:
          "¿Entra a Mobil Predictor para realizar tu predicción. ¿Cómo le irá en la Gran Carrera a Checo Pérez? Llena los campos y gana puntos.",
      },
    ],
  },
  data() {
    return {
      questions: [],
      form: [
        {
          pregunta1: "¿En qué posición Checo terminará la carrera?",
          respuesta1: "",
        },
        {
          pregunta2: "¿En qué posición Checo terminará la Qualy?",
          respuesta2: "",
        },
        {
          pregunta3:
            "¿Número de paradas en boxes que Checo hará en la carrera?",
          respuesta3: "",
        },
        {
          pregunta4: "¿Con qué neumáticos empezará la carrera?",
          respuesta4: "",
        },
        {
          pregunta5: "¿Será el piloto del día?",
          respuesta5: "",
        },
      ],
      showQuestions: false,
      isFormVisible: false,
    };
  },
  methods: {
    submitFormQuestionsMobil() {
      const predictor = JSON.parse(localStorage.getItem("predictor"));
      const email = predictor.email;

      this.form.forEach((item, index) => {

        const existingPredictions = JSON.parse(
          localStorage.getItem("predicciones")
        );

        if (existingPredictions) {
          existingPredictions.Checo = this.form;
          localStorage.setItem(
            "predicciones",
            JSON.stringify(existingPredictions)
          );
        } else {
          localStorage.setItem(
            "predicciones",
            JSON.stringify({ Checo: this.form })
          );
        }

        document.cookie =
          "respuestasCheco=checo; expires=" +
          new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toUTCString();

        setTimeout(() => {
          this.$router.push("/predicciones-pilotos-verstappen").catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              throw err;
            }
          });
        }, 1000);
      });
    },
    alertaCambioPaginaMax() {
      if (document.cookie.indexOf("respuestasCheco=checo") === -1) {
        Swal.fire({
          title: "¡Espera!",
          text:
            "Estás a punto de cambiar de página, envía tus respuestas antes de irte",
          icon: "info",
          confirmButtonText: "Ok",
          background: "rgba(0, 20, 79, 0.8)",
          confirmButtonColor: "#ff0000",
          color: "#fff",
        });
        setTimeout(() => {
          this.$router.push("/predicciones-pilotos-verstappen");
        }, 1500);
      } else {
        this.$router.push("/predicciones-pilotos-verstappen");
      }
    },
    alertaCambioPaginaMobil() {
      if (document.cookie.indexOf("respuestasCheco=checo") === -1) {
        Swal.fire({
          title: "¡Espera!",
          text:
            "Estás a punto de cambiar de página, envía tus respuestas antes de irte",
          icon: "info",
          confirmButtonText: "Ok",
          background: "rgba(0, 20, 79, 0.8)",
          confirmButtonColor: "#ff0000",
          color: "#fff",
        });
        setTimeout(() => {
          this.$router.push("/predicciones-mobil");
        }, 1500);
      } else {
        this.$router.push("/predicciones-mobil");
      }
    },
  },
  computed: {
    isFormComplete() {
      return this.form.every(item => {
        return Object.values(item).every(value => value !== "");
      });
    },
  },
  watch: {
    isFormComplete(newVal) {
      console.log('isFormComplete:', newVal);
    }
  },
  mounted() {
  if (!localStorage.getItem("predictor")) {
    this.$router.push("/iniciar-sesion");
  }

  if (localStorage.getItem("predicciones")) {
    const existingPredictions = JSON.parse(localStorage.getItem("predicciones"));
  if (existingPredictions && existingPredictions.Checo) {
    this.form = existingPredictions.Checo;
    }
  }

  const cookieExists = document.cookie.includes("PrediccionesEnviadas");
  this.isFormVisible = cookieExists;
  },
};
</script>
